// Skills Icons
import medal from "./images/medal.svg"
import toolBox from "./images/tool-box.svg"
import origami from "./images/origami.svg"

// Work photo

import miks_1 from "./images/miks/1.jpg";
import miks_2 from "./images/miks/2.jpg";
import miks_3 from "./images/miks/3.jpg";
import miks_4 from "./images/miks/4.jpg";
import miks_5 from "./images/miks/5.jpg";
import miks_6 from "./images/miks/6.jpg";
import miks_7 from "./images/miks/7.jpg";
import miks_8 from "./images/miks/8.jpg";
import miks_9 from "./images/miks/9.jpg";
import miks_10 from "./images/miks/10.jpg";
import miks_11 from "./images/miks/11.jpg";
import miks_12 from "./images/miks/12.jpg";
import miks_13 from "./images/miks/13.jpg";
import miks_14 from "./images/miks/14.jpg";
import miks_15 from "./images/miks/15.jpg";
import miks_16 from "./images/miks/16.jpg";
import miks_17 from "./images/miks/17.jpg";




export default {
  //(Please Do Not Remove The comma(,) after every variable)
  //Change The Website Template

  //   Header Details ---------------------
  name: "CaSO",
  headerTagline: [
    //Line 1 For Header
    "mi radimo gips",
    //Line 2 For Header
    "Od ugla do ugla",
    //Line 3 For Header
    "Radimo pravo i ispravno",
  ],
  //   Header Paragraph
  headerParagraph:
    "Od ugla do ugla. Od ivice do ivice. Sve vrste gipsarskih radova. Sve radimo pravo i ispravno.",
  //Contact Email
  contactEmail: "caso.beograd@gmail.com",

  // End Header Details -----------------------

  // Work Section ------------------------
  projects: [
    {
      title: "Miks", //Project Title - Add Your Project Title Here
      para:
        "", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        miks_1,
      //Project URL - Add Your Project Url Here
      // url: "http://chetanverma.com/",
    },
    {
      title: "Miks", //Project Title - Add Your Project Title Here
      para:
        "", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        miks_14,
      //Project URL - Add Your Project Url Here
      // url: "http://chetanverma.com/",
    },
    {
      title: "Miks", //Project Title - Add Your Project Title Here
      para:
        "", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        miks_3,
      //Project URL - Add Your Project Url Here
      // url: "http://chetanverma.com/",
    },
    {
      title: "Miks", //Project Title - Add Your Project Title Here
      para:
        "", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        miks_4,
      //Project URL - Add Your Project Url Here
      // url: "http://chetanverma.com/",
    },
    {
      title: "Miks", //Project Title - Add Your Project Title Here
      para:
        "", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        miks_5,
      //Project URL - Add Your Project Url Here
      // url: "http://chetanverma.com/",
    },
    {
      title: "Miks", //Project Title - Add Your Project Title Here
      para:
        "", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        miks_6,
      //Project URL - Add Your Project Url Here
      // url: "http://chetanverma.com/",
    },
    {
      title: "Miks", //Project Title - Add Your Project Title Here
      para:
        "", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        miks_7,
      //Project URL - Add Your Project Url Here
      // url: "http://chetanverma.com/",
    },
    {
      title: "Miks", //Project Title - Add Your Project Title Here
      para:
        "", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        miks_8,
      //Project URL - Add Your Project Url Here
      // url: "http://chetanverma.com/",
    },
    {
      title: "Miks", //Project Title - Add Your Project Title Here
      para:
        "", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        miks_9,
      //Project URL - Add Your Project Url Here
      // url: "http://chetanverma.com/",
    },
    {
      title: "Miks", //Project Title - Add Your Project Title Here
      para:
        "", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        miks_10,
      //Project URL - Add Your Project Url Here
      // url: "http://chetanverma.com/",
    },
    {
      title: "Miks", //Project Title - Add Your Project Title Here
      para:
        "", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        miks_11,
      //Project URL - Add Your Project Url Here
      // url: "http://chetanverma.com/",
    },
    {
      title: "Miks", //Project Title - Add Your Project Title Here
      para:
        "", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        miks_12,
      //Project URL - Add Your Project Url Here
      // url: "http://chetanverma.com/",
    },
    {
      title: "Miks", //Project Title - Add Your Project Title Here
      para:
        "", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        miks_13,
      //Project URL - Add Your Project Url Here
      // url: "http://chetanverma.com/",
    },
    {
      title: "Miks", //Project Title - Add Your Project Title Here
      para:
        "", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        miks_2,
      //Project URL - Add Your Project Url Here
      // url: "http://chetanverma.com/",
    },
    {
      title: "Miks", //Project Title - Add Your Project Title Here
      para:
        "", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        miks_15,
      //Project URL - Add Your Project Url Here
      // url: "http://chetanverma.com/",
    },
    {
      title: "Miks", //Project Title - Add Your Project Title Here
      para:
        "", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        miks_16,
      //Project URL - Add Your Project Url Here
      // url: "http://chetanverma.com/",
    },
    {
      title: "Miks", //Project Title - Add Your Project Title Here
      para:
        "", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        miks_17,
      //Project URL - Add Your Project Url Here
      // url: "http://chetanverma.com/",
    },
    {
      title: "Miks", //Project Title - Add Your Project Title Here
      para:
        "", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        miks_6,
      //Project URL - Add Your Project Url Here
      // url: "http://chetanverma.com/",
    }

  ],

  // End Work Section -----------------------

  // About Secton --------------
  aboutParaOne:
    "CaSO gips | gipsarski radovi | Beograd | Srbija",
  aboutParaTwo:
    "CaSO gips | gipsarski radovi | Beograd | Srbija",
  aboutParaThree:
    "CaSO gips | gipsarski radovi | Beograd | Srbija",
  aboutImage:
    "https://images.unsplash.com/photo-1519713958759-6254243c4a53?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80",

  //   End About Section ---------------------

  // Skills Section ---------------

  //   Import Icons from the top and link it here

  skills: [
    {
      img: toolBox,
      para:
        "Svi mi u CaSO radimo tako da uvek sa ponosom možemo da se svakim završenim poslom i pohvalimo.",
    },
    {
      img: origami,
      para:
        "Naš rad govori o nama dugo pošto je završen. Koristimo samo najbolje i ne pravimo kompromise.",
    },
    {
      img: medal,
      para:
        "Postavili smo sebi najviše ciljeve. Idemo ka ostvarivanju CaSO imena kao garancije posvećenosti i kvaliteta.",
    },

  ],

  // End Skills Section --------------------------

  //   Promotion Section --------------------------

  // promotionHeading: "Heading",
  // promotionPara:
  //   "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  // End Promotion Section -----------------

  //   Contact Section --------------

  contactSubHeading: "",
  // social: [
  //   // Add Or Remove The Link Accordingly
  //   { img: githubIcon, url: "https://github.com/chetanverma16" },
  //   {
  //     img: codepenIcon,
  //     url: "https://www.codepen.com/",
  //   },
  //   {
  //     img: dribbbleIcon,
  //     url: "https://dribbble.com/chetanverma",
  //   },
  //   {
  //     img: instagramIcon,
  //     url: "https://www.instagram.com/",
  //   },
  // ],

  // End Contact Section ---------------
}

// Thanks for using this template, I would love to hear from you contact me at hello@chetanverma.com
