import React from "react"
import Fade from "react-reveal/Fade"
import data from "../yourdata"

const Skills = () => {
  return (
    <div className="section" id="onama">
      <div className="container">
        <div className="skills-container">
          <div className="skills-grid">
            <Fade bottom cascade>
              {data.skills.map((skill, index) => (
                <div className="skill" key={index}>
                  <img src={skill.img} alt="css"></img>
                  <p>{skill.para}</p>
                </div>
              ))}
            </Fade>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Skills
