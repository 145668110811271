import React, { useState } from "react"
import Fade from "react-reveal/Fade"
import data from "../yourdata"

const Header = () => {
  const [size, setSize] = useState(false);

  return (
    <div className="section" id="home">
      <div className="container">
        <div className="header-wrapper css-typing">
          {/* <Fade bottom> */}

  <h2>
  zdravo, mi smo {data.name}{" "}
              <span role="img" aria-label="Emoji" class={`wave ${size ? "large" : "small"}`} onClick={() => setSize(!size)}>
                👋
              </span>  </h2>

            {/* <h2>
              zdravo, mi smo {data.name}{" "}
              <span role="img" aria-label="Emoji" class={`wave ${size ? "large" : "small"}`} onClick={() => setSize(!size)}>
                👋
              </span>
            </h2> */}
          {/* </Fade> */}
          <Fade bottom cascade>
            <div className="heading-wrapper">
              <h1>
                {data.headerTagline[0]
                  ? data.headerTagline[0]
                  : "Building digital"}
              </h1>
            </div>
          </Fade>
          <Fade bottom>
            <p>{data.headerParagraph}</p>
          </Fade>
          <Fade bottom>
            <a
              href={`mailto:${data.contactEmail ? data.contactEmail : "caso.beograd@gmail.com"
                }`}
              className="primary-btn"
            >
              javite se
            </a>
          </Fade>
          <p>+381 64 989 2518</p>
        </div>
      </div>
    </div >
  )
}

export default Header
