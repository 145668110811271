import React from "react"
import Fade from "react-reveal/Fade"
import data from "../yourdata"

const Footer = () => {
  return (
    <div className="section" id="contact">
      <div className="container">
        <div className="footer-container">
          <Fade bottom cascade>
            <a className="email-link" href={`mailto:${data.contactEmail}`}>
              {data.contactEmail}
            </a>
          </Fade>

          {/* <div className="social-icons">
            {data.social.map((socialLink, index) => (
              <a
                key={index}
                href={socialLink.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={socialLink.img} alt="icons"></img>
              </a>
            ))}
          </div> */}
          <span style={{ fontSize: 8 }}>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            Made With < icon >❤</icon > by{" "}
            <a href="https://www.mlnkv.com/">Nemanja</a>
          </span >
        </div >
      </div >
    </div >
  )
}

export default Footer
